// @flow
// import { all, takeLatest } from "redux-saga/effects";
import { all } from 'redux-saga/effects';
// import DebugConfig from '../config/DebugConfig';
// import API from '../services/Api';
// import FixtureAPI from '../services/FixtureApi';
import ContactSagas from '../pages/contact/sagas';
/* ------------- Types ------------- */

/* ------------- Sagas ------------- */

/* ------------- API ------------- */

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.
// const api = DebugConfig.useFixtures ? FixtureAPI : API.create();

/* ------------- Connect Types To Sagas ------------- */

// $FlowFixMe
export default function* root() {
  yield all([...ContactSagas]);
}
