import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  // Show Confirmation
  showInformationModal: ['message'],

  // Request
  sendMessageRequest: ['data'], // Send message Request

  // Response
  sendMessageSuccessResponse: [], // Send message success Response
  sendMessageFailureResponse: [] // Send message success Response
});

export const ContactTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  message: {}
});

/* ------------- Reducers ------------- */

// Cities of created Games Retrieve Success
export const showInformationModal = (state, message) =>
  state.merge({ ...message });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  // Response
  [Types.SHOW_INFORMATION_MODAL]: showInformationModal
});

/* ------------- Selectors ------------- */
