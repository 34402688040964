// Hoc for Loading Management
// @flow
import React from 'react';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import { createLoadingSelector } from '../redux/LoadingRedux';

//* ******************** */
// REDUX PROPS */
//* ******************** */
type ReduxStateProps = {
  isLoading: boolean
};

// Loading Container HOC
const LoadingContainer = (loadingActions: Array<string>) => (
  Component: any
) => {
  function loadingWrapper(props: any) {
    const { isLoading, dispatch, ...rest } = props;
    return (
      <LoadingOverlay
        active={isLoading}
        spinner
        text="Loading your content..."
        className="d-flex flex-fill flex-column justify-content-start"
      >
        <Component {...rest} />
        <div className="d-flex" />
      </LoadingOverlay>
    );
  }
  const loadingSelector = createLoadingSelector(loadingActions);

  //* ******************** */
  // MAP STATE TO PROPS */
  //* ******************** */
  const mapStateToProps: any = (state: Object): ReduxStateProps => ({
    isLoading: loadingSelector(state.loading)
  });

  return connect(mapStateToProps)(loadingWrapper);
};

export default LoadingContainer;
