import React from 'react';
import AOS from 'aos';
import { HashLink as Link } from 'react-router-hash-link';
import flopLogo from '../../../assets/img/Flop_logo.png';
import flopIllustration from '../../../assets/img/UIRender_animated.gif';
import connectIllustration from '../../../assets/img/Network_illustration_small.png';
import findIllustration from '../../../assets/img/Localization_illustration_small.png';
import cardIllustration from '../../../assets/img/CardsCraft_Illustration_small.png';
import CardHorizontal from '../../../assets/img/CardsHorizontal_illustration_small.png';
import B2BGrowth from '../../../assets/img/B2BGrowth_illustration_small.png';
// import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple } from '@fortawesome/free-brands-svg-icons';
import { faAndroid } from '@fortawesome/free-brands-svg-icons';

// import { title, separator, displayText } from './Styles.module.scss';

//* ******************** */
// COMPONENTS PROPS */
//* ******************** */

//* ******************** */
// COMPONENTS STATE */
//* ******************** */

class Flop extends React.Component {
  constructor(props) {
    super(props);
    AOS.init();
  }

  // Component Will Receive Props
  componentWillReceiveProps() {
    AOS.refresh();
  }

  // Component Did Mount
  componentDidMount() {
    document.title = 'Flop Application';
  }
  //* ******************** */
  // USER ACTIONS */
  //* ******************** */

  //* ******************** */
  // RENDER */
  //* ******************** */

  render() {
    return (
      <div className="d-flex flex-fill flex-column justify-content-start align-items-start m-2 m-md-5 containerApp">
        {/* Fist Part */}
        <div
          className="d-none d-md-flex flex-row justify-content-center align-items-start"
          data-aos="fade-left"
          data-aos-offset="50"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <img width="60px" src={flopLogo} alt="Flop Logo" />
          <div className="d-flex flex-column justify-content-center align-items-start ml-2">
            <h3 className="text-left"> First Land of Poker</h3>
            <p>
              Connect and share with friends, find your favorite tournaments and
              casino, create your games and much more !
            </p>
          </div>
        </div>
        <hr className="d-none d-md-flex weopt" />
        <div className="d-flex flex-wrap flex-fill flex-row w-100 justify-content-between align-items-between mt-5 m-3 m-xl-5">
          <div
            style={{ position: 'relative' }}
            className="d-none d-xl-flex flex-fill medium-width flex-column justify-content-center align-items-end p-5"
            data-aos="fade-up"
            data-aos-offset="50"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            <img
              src={flopIllustration}
              width="100%"
              alt="Flop-Illustration"
              class="rounded"
            />
            <Link
              className="btn btn-primary mt-5"
              color="primary"
              style={{
                position: 'absolute',
                bottom: '70px',
                left: '50%',
                marginLeft: '-80px'
              }}
              to="#flop"
            >
              LEARN MORE
            </Link>
          </div>
          <div className="d-flex flex-fill medium-width flex-column justify-content-around align-items-end pl-xl-5">
            <div
              className="d-flex flex-row justify-content-start align-items-center"
              data-aos="fade-left"
              data-aos-offset="50"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <img
                className="d-none d-md-flex"
                width="150px"
                src={connectIllustration}
                alt="Connect&Share-Illustration"
              />
              <div className="d-flex flex-column justify-content-start align-items-start ml-md-5">
                <h4>Connect & Share</h4>
                <hr className="d-flex d-md-none weopt" />
                <p className="mt-2">
                  Find friends, connect with them, and share your passion for
                  poker with them
                </p>
              </div>
            </div>
            <div
              className="d-flex flex-row justify-content-start align-items-center mt-4"
              data-aos="fade-left"
              data-aos-offset="50"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <img
                className="d-none d-md-flex"
                width="150px"
                src={findIllustration}
                alt="Find&Play-Illustration"
              />
              <div className="d-flex flex-column justify-content-start align-items-start ml-md-5">
                <h4>Find and Play</h4>
                <hr className="d-flex d-md-none weopt" />
                <p className="mt-2">
                  Get real time information on poker rooms and find the right
                  game for the right time
                </p>
              </div>
            </div>
            <div
              className="d-flex flex-row justify-content-start align-items-center mt-4"
              data-aos="fade-left"
              data-aos-offset="50"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <img
                className="d-none d-md-flex"
                width="150px"
                src={cardIllustration}
                alt="CreateYourGame-Illustration"
              />
              <div className="d-flex flex-column justify-content-start align-items-start ml-md-5">
                <h4>Create your games</h4>
                <hr className="d-flex d-md-none weopt" />
                <p className="mt-2">
                  Invite fellow poker players to play and send your interest
                  list to your favorite poker room
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Second Part */}
        <div
          id="flop"
          className="d-flex flex-fill flex-column justify-content-start align-items-start m-3 m-xl-5"
        >
          <h2
            className="d-flex text-left titleApp"
            data-aos="fade-left"
            data-aos-offset="50"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            First Land of Poker For Players
          </h2>
          <hr className="d-flex weopt" />
          <div className="d-flex flex-fill flex-row justify-content-start align-items-start justify-content-start p-xl-3 mt-5">
            <img
              className="d-none d-md-flex"
              width="250px"
              alt="flop-player-illustration"
              src={CardHorizontal}
              data-aos="fade-up"
              data-aos-offset="50"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            />
            <p
              className="pl-xl-5"
              data-aos="fade-left"
              data-aos-offset="50"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              Share knowledge, tell stories and connect with fellow poker
              players.
              <br /> <br />
              Use our local poker room finder to quickly see all poker games
              nearby - or plan ahead and check poker room details of anywhere
              you might be travelling next.
              <br /> <br />
              Create games. No waiting around wondering whether you get to play
              or not: let other poker players know you are interested in playing
              in a specific card room at a specific time, invite players to your
              interest list and let flop send your interest list to the choosen
              poker room!
              <br /> <br />
              Get notifications from card rooms near or far on games, events and
              special offers.
              <br /> <br />
              Find your favorite tournaments, register, check seat draws, chip
              counts, prize pools, results and much more.
            </p>
          </div>
          <div className="d-flex flex-row justify-content-center justify-content-xl-end w-100 align-middle">
            <a
              href="https://itunes.apple.com/us/app/first-land-of-poker/id1441475762?l=fr&ls=1&mt=8"
              target="_blank"
              rel="noopener noreferrer"
              className="mr-3 btn btn-primary"
              data-aos="fade-right"
              data-aos-offset="50"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <span>
                <FontAwesomeIcon icon={faApple} size="2x" />
              </span>
              <div className="h-100 my-auto">Download for iOS</div>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.weopt.flop"
              target="_blank"
              rel="noopener noreferrer"
              className="mr-3 align-middle btn btn-primary"
              data-aos="fade-right"
              data-aos-offset="50"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <span>
                <FontAwesomeIcon icon={faAndroid} size="2x" />
              </span>
              <div className="h-100 my-auto">Download for Android</div>
            </a>
          </div>
        </div>
        {/* Third Part */}
        <div className="d-flex flex-fill flex-column justify-content-start align-items-start mt-5 m-3 m-xl-5">
          <h2
            className="d-flex text-left titleApp"
            data-aos="fade-left"
            data-aos-offset="50"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            First Land of Poker For Casino
          </h2>
          <hr className="d-flex weopt" />
          <div className="d-flex flex-fill flex-row justify-content-start align-items-start justify-content-start p-xl-3 mt-5">
            <img
              className="d-none d-md-flex"
              width="250px"
              alt="flop-player-illustration"
              src={B2BGrowth}
              data-aos="fade-up"
              data-aos-offset="50"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            />
            <p
              className="pl-xl-5"
              data-aos="fade-left"
              data-aos-offset="50"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              Share your Poker Room promotions, events and cash game info with
              poker players around the world or send out targeted messages and
              notifications to certain poker playing groups. <br />
              Communicate and connect.
              <br /> <br />
              Help poker players find your poker room. Display real-time
              information on your tournaments and waiting lists and fill up your
              poker tables
              <br /> <br />
              The strength of a poker room is its player pool – players want to
              find lively and active poker rooms with plenty of people
              constantly coming and going.
              <br />
              Flop is every poker player’s and poker room’s must-have app.
            </p>
          </div>
        </div>
        {/* <div className="d-flex flex-row justify-content-center justify-content-xl-end w-100">
          <Button
            onClick={() => this.props.history.push('/products/flop-ad')}
            color="primary"
            className="mr-3 align-middle btn btn-primary"
            data-aos="fade-right"
            data-aos-offset="50"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            DISCOVER FLOP AD
          </Button>
        </div> */}
      </div>
    );
  }
}

export default Flop;
