import React from 'react';
import AOS from 'aos';
import story from '../../../assets/img/Growth_illustration_small.png';
import flopLogo from '../../../assets/img/Flop_logo.png';
import mission from '../../../assets/img/Target_illustration_small.png';
import {
  VerticalTimeline,
  VerticalTimelineElement
} from 'react-vertical-timeline-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGavel,
  faUsers,
  faMobileAlt
} from '@fortawesome/free-solid-svg-icons';
import { faChrome } from '@fortawesome/free-brands-svg-icons';

//* ******************** */
// COMPONENTS PROPS */
//* ******************** */

//* ******************** */
// COMPONENTS STATE */
//* ******************** */

class Company extends React.Component {
  constructor(props) {
    super(props);
    AOS.init();
  }

  // Component Will Receive Props
  componentWillReceiveProps() {
    AOS.refresh();
  }

  // Component Did Mount
  componentDidMount() {
    document.title = 'The Compagny';
  }
  //* ******************** */
  // USER ACTIONS */
  //* ******************** */

  //* ******************** */
  // RENDER */
  //* ******************** */

  render() {
    return (
      <div className="d-flex flex-fill flex-column justify-content-start align-items-start m-5 w-75">
        <h2
          className="d-none d-md-flex text-left"
          data-aos="fade-left"
          data-aos-offset="50"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          The Company
        </h2>
        <hr className="d-none d-md-flex weopt" />
        <div className="d-flex flex-fill flex-column justify-content-between align-items-center justify-content-start p-xl-3">
          <div
            className="d-flex flex-row justify-content-start align-items-center mt-4"
            data-aos="fade-up"
            data-aos-offset="50"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            <img
              className="d-none d-md-flex"
              width="150px"
              src={mission}
              alt="Mission-Illustration"
            />
            <div className="d-flex flex-column justify-content-start align-items-start ml-md-5">
              <h4>Our Mission & Philosophy</h4>
              <hr className="d-flex d-md-none weopt" />
              <p className="mt-2">
                Our mission is to together set new standards of poker gaming
                excellence. <br />
                We want to give poker players the most compelling gaming
                experience possible. <br />
                We connect the poker world in new ways, reinventing the way
                players find casinos, poker rooms, games and fellow players.{' '}
                <br />
                We provide poker rooms and their staff with crucial tools that
                will help the daily running of events and cash games, optimize
                business and revenue, bring in players and foremost connect and
                communicate with players in an innovative new fashion.
              </p>
            </div>
          </div>
          <div
            className="d-flex flex-row justify-content-start align-items-start w-100 mt-5"
            data-aos="fade-up"
            data-aos-offset="50"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            <img
              className="d-none d-md-flex"
              width="150px"
              src={story}
              alt="Story-Illustration"
            />
            <div className="d-flex flex-column justify-content-start align-items-start ml-md-5 w-100">
              <h4>Our Story</h4>
              <hr className="d-flex d-md-none weopt" />
              <VerticalTimeline>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  date="July 2018"
                  iconStyle={{ background: '#819299', color: '#fff' }}
                  icon={
                    <FontAwesomeIcon color="#ffffff" icon={faGavel} size="2x" />
                  }
                >
                  <h3 className="vertical-timeline-element-title">
                    WeOpt Founded
                  </h3>
                  <p>
                    WeOpt co-founded in Monaco by Patrik Antonius and
                    Idriss Bellamine
                  </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date="August 2018"
                iconStyle={{ background: '#819299', color: '#fff' }}
                icon={
                  <FontAwesomeIcon color="#ffffff" icon={faUsers} size="2x" />
                }
              >
                <h3 className="vertical-timeline-element-title">
                  New Brands FLOP &amp; PAPC
                </h3>
                <p>
                  Launch and patent of two brands &laquo;Patrik Antonius
                  Poker Challenge&raquo; (PAPC) and &laquo;First Land Of
                  Poker&raquo; (FLOP)
                </p>
              </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  date="September 2018"
                  iconStyle={{ background: '#819299', color: '#fff' }}
                  icon={
                    <FontAwesomeIcon color="#ffffff" icon={faUsers} size="2x" />
                  }
                >
                  <h3 className="vertical-timeline-element-title">
                    IT Team
                  </h3>
                  <p>
                    5 talented IT engineers joined WeOpt to build its digital Products
                  </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  date="October 2018"
                  iconStyle={{ background: '#819299', color: '#fff' }}
                  icon={
                    <FontAwesomeIcon
                      color="#ffffff"
                      icon={faChrome}
                      size="2x"
                    />
                  }
                >
                  <h3 className="vertical-timeline-element-title">PAPC Tallinn</h3>
                  <p>Set up of the First Edition of PAPC in Tallinn, Estonia, in
                  Partnership with Olympic Casino Tallinn, from 1<sup>st</sup> of 
                  April 2019 till 7<sup>th</sup> of April 2019</p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  date="March 2019 "
                  iconStyle={{ background: '#43b99d', color: '#fff' }}
                  icon={
                    <FontAwesomeIcon
                      color="#ffffff"
                      icon={faMobileAlt}
                      size="2x"
                    />
                  }
                >
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <div className="d-flex flex-column justify-content-center align-items-start">
                      <h3 className="vertical-timeline-element-title">
                      	First Land Of Poker introduction
                      </h3>
                      <p>
                      	Introducing FLOP mobile application, Exclusive Social Media for Poker Players,
                      	during PAPC Talinn Edition one.
                      </p>
                    </div>
                    <img width="60px" src={flopLogo} alt="Flop Logo" />
                  </div>
                </VerticalTimelineElement>
              </VerticalTimeline>
            </div>
          </div>
          {/* <div
            className="d-flex flex-row justify-content-start align-items-center mt-4"
            data-aos="fade-up"
            data-aos-offset="50"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            <img
              className="d-none d-md-flex"
              width="150px"
              src={brand}
              alt="Brand-Illustration"
            />
            <div className="d-flex flex-column justify-content-start align-items-start ml-md-5">
              <h4>Our Brand Strategy</h4>
              <hr className="d-flex d-md-none weopt" />
              <p className="mt-2">
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae ab illo inventore veritatis et quasi architecto beatae
                vitae dicta sunt explicabo.
                <br />
                Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit
                aut fugit, sed quia consequuntur magni dolores eos qui ratione
                voluptatem sequi nesciunt.
              </p>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}

export default Company;
