import React from 'react';
import AOS from 'aos';
import papcBanner from '../../assets/img/PAPC_banner.png';

//* ******************** */
// COMPONENTS PROPS */
//* ******************** */

//* ******************** */
// COMPONENTS STATE */
//* ******************** */

class Events extends React.Component {
  constructor(props) {
    super(props);
    AOS.init();
  }

  // Component Will Receive Props
  componentWillReceiveProps() {
    AOS.refresh();
  }

  // Component Did Mount
  componentDidMount() {
    document.title = 'Events';
  }
  //* ******************** */
  // USER ACTIONS */
  //* ******************** */

  //* ******************** */
  // RENDER */
  //* ******************** */

  render() {
    return (
      <div
        className="d-flex flex-fill flex-column justify-content-center align-items-center mt-5"
        data-aos="fade-up"
        data-aos-offset="50"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
      >
        <h1 className="titleApp">Our Last Live Event</h1>
        <img className="mt-5" src={papcBanner} alt="PAPC Banner" />
        <h5 className="mt-5">From April 1st to 7th 2019</h5>
        <h5>TALINN, ESTONIA</h5>

        <a
          className="btn btn-primary mt-5"
          href="https://www.pa-pc.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          EVENT DETAILS
        </a>
      </div>
    );
  }
}

export default Events;
