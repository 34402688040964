// @flow
import { createReducer, Types as ReduxSauceTypes } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import _ from 'lodash';

/* ------------- Types and Action Creators ------------- */

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({});

/* ------------- Reducers ------------- */
// We store here each Action with a loading
export const loadingReducer = (state: any, action: any) => {
  const { type } = action;
  // S'il y a une erreur on reset l'ensemble des loadings
  if (type === 'FAILURE_RESPONSE') {
    return INITIAL_STATE;
  }

  const matches = /(.*)_(REQUEST|SUCCESS_RESPONSE|FAILURE_RESPONSE)/.exec(type);

  // not a *_REQUEST / *_SUCCESS /  *_FAILURE actions, so we ignore them
  if (!matches) return state;

  const [, requestName, requestState] = matches;

  // Store whether a request is happening at the moment or not
  // e.g. will be true when receiving GET_TODOS_REQUEST
  //      and false when receiving GET_TODOS_SUCCESS / GET_TODOS_FAILURE
  return state.merge({
    [requestName]: requestState === 'REQUEST'
  });
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [ReduxSauceTypes.DEFAULT]: loadingReducer
});

/* ------------- Selectors ------------- */

// Selector For Loading
export const createLoadingSelector = (actions: any) => (state: any) =>
  // returns true only when all actions is not loading
  _(actions).some(action => _.get(state, `${action}`));
