// @flow
import * as React from 'react';
import { Link } from 'react-router-dom';

//* ******************** */
// COMPONENTS PROPS */
//* ******************** */
type Props = {};

type State = {};

/**
 * NotFoundPage
 */
class NotFoundPage extends React.Component<Props, State> {
  static defaultProps = {};

  //* ******************** */
  // USER ACTIONS */
  //* ******************** */

  //* ******************** */
  // RENDER */
  //* ******************** */
  render() {
    return (
      <div className="d-flex flex-fill flex-column justify-content-center align-items-center w-100">
        <div className="error-template">
          <h1>Oops!</h1>
          <h2>404 Not Found</h2>
          <div className="error-details">
            Sorry, an error has occured, Requested page not found!
          </div>
          <div className="error-actions">
            <Link to="/" className="btn btn-primary btn-lg">
              <span className="glyphicon glyphicon-home" />
              Take Me Home{' '}
            </Link>
            <Link to="/contact" className="btn btn-default btn-lg">
              <span className="glyphicon glyphicon-envelope" /> Contact Support{' '}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFoundPage;
