// @flow
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import DebugConfig from '../config/DebugConfig';

// creates the store
export default (rootReducer: any, rootSaga: any, history: any) => {
  /* ------------- Redux Configuration ------------- */

  const middleware = [];
  const enhancers = [];

  /* ------------- Saga Middleware ------------- */
  // Define Saga MiddleWare
  const sagaMiddleware = createSagaMiddleware();
  middleware.push(sagaMiddleware);

  /* ------------- Assemble Middleware ------------- */
  // Define enhancers and apply Middleware
  if (DebugConfig.reduxLogging) {
    // Active DevTools if it's Dev Mode
    enhancers.push(composeWithDevTools(applyMiddleware(...middleware)));
  } else {
    enhancers.push(applyMiddleware(...middleware));
  }
  const composeMiddleware: any = compose(...enhancers);

  // $FlowFixMe
  const store = createStore(rootReducer, composeMiddleware);

  // kick off root saga
  // $FlowFixMe
  const sagasManager = sagaMiddleware.run(rootSaga);

  return {
    store,
    sagasManager,
    sagaMiddleware
  };
};
