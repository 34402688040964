import { takeLatest } from 'redux-saga/effects';

/* ------------- Types ------------- */

import { ContactTypes } from '../redux/ContactRedux';
/* ------------- Sagas ------------- */

import { sendMessageRequest } from './ContactSagas';
import Api from '../../../services/Api';

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.
const api = Api.create();

const ContactSagas = [
  // Contact Sagas

  takeLatest(ContactTypes.SEND_MESSAGE_REQUEST, sendMessageRequest, api)
];

export default ContactSagas;
