import React from 'react';
import { Field, reduxForm, getFormValues, reset } from 'redux-form';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ContactActions from './redux/ContactRedux';
import LoadingContainer from '../../components/LoadingContainer';
import { buttonSend, title, labelTitle, link } from './Styles.module.scss';

//* ******************** */
// COMPONENTS STATE */
//* ******************** */

// Form NAME
const formName = 'ContactForm';

const required = value => (value ? undefined : 'Please, fill out this field');
const requiredAgree = value =>
  value
    ? undefined
    : 'In order to send your message, you need to agree our privacy policy.';
const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'The email address format is not valid. Please check your email address format before sending the email.'
    : undefined;

const renderField = ({
  input,
  type,
  label,
  htmlFor,
  meta: { touched, error, warning }
}) => (
  <div>
    <label
      htmlFor={htmlFor}
      className={`text-dark control-label ${labelTitle}`}
    >
      {label}
    </label>
    <input
      {...input}
      type={type}
      className="w-100 border border-top-0 border-right-0 border-left-0"
    />
    <div className="w-100">
      {touched && (error && <span className="text-danger h6">{error}</span>)}
    </div>
  </div>
);

const renderFieldArea = ({
  input,
  type,
  meta: { touched, error, warning }
}) => (
  <div>
    <textarea
      {...input}
      rows="10"
      type="textarea"
      component="textarea"
      className="w-100 border"
    />
    {touched && (error && <span className="text-danger h6">{error}</span>)}
  </div>
);

const renderCheckBox = ({
  input,
  type,
  text,
  meta: { touched, error, warning }
}) => (
  <div>
    <div className="d-flex flex-row align-items-center">
      <input {...input} type={type} className="mr-2" />
      <p className={`text-dark pt-3`}>
        I agree to the{' '}
        <Link
          to="/privacy-policy"
          rel="noopener noreferrer"
          target="_blank"
          className={`${link}`}
        >
          Privacy policy
        </Link>
      </p>
    </div>
    <div className="w-100 mb-5">
      {touched && (error && <span className="text-danger h6">{error}</span>)}
    </div>
  </div>
);

const FormArea = props => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <form
        data-toggle="validator"
        onSubmit={props.handleSubmit(props.sendMessage)}
        className="d-flex flex-column bg-white shadow rounded p-3 p-md-5 col-11 col-md-8 col-lg-6"
      >
        <div className="pb-2 ">
          <p className={`text-dark ${title}`}>Leave us a message</p>
        </div>
        <div className="form-group required">
          <Field
            htmlFor="contactName"
            label="Your name"
            component={renderField}
            type="text"
            name="contactName"
            className="w-100 border border-top-0 border-right-0 border-left-0"
            validate={[required]}
          />
          <div className="invalid-feedback">Please enter your name</div>
        </div>
        <div className="form-group required">
          <Field
            htmlFor="contactEmail"
            label="Your email address"
            component={renderField}
            type="email"
            className="w-100 border border-top-0 border-right-0 border-left-0"
            name="contactEmail"
            validate={[email, required]}
          />
          <div className="invalid-feedback">
            Please enter your email address
          </div>
        </div>
        <div className="form-group required pb-2">
          <label
            htmlFor="content"
            className={`text-dark control-label ${labelTitle}`}
          >
            Subject{' '}
          </label>
          <div className="input-group mb-3">
            <Field
              component="select"
              name="subject"
              className="custom-select"
              required
            >
              <option defaultValue>Choose...</option>
              <option value="Accommodation">Contact</option>
              <option value="Others">Others</option>
            </Field>
          </div>
        </div>
        <div className="form-group required pb-2">
          <label
            htmlFor="content"
            className={`text-dark control-label ${labelTitle}`}
          >
            Your message{' '}
          </label>
          <Field
            component={renderFieldArea}
            type="textarea"
            name="content"
            rows="10"
            validate={[required]}
          />
        </div>
        <div className="form-group required mb-0">
          <Field
            htmlFor="privacy"
            component={renderCheckBox}
            type="checkbox"
            name="privacy"
            validate={[requiredAgree]}
          />
          <div className="invalid-feedback">Please enter your name</div>
        </div>
        <div className="row">
          <div className="mx-auto">
            <button
              className={buttonSend}
              type="submit"
              disabled={props.submitting}
            >
              Send
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      message: {
        header: '',
        content: ''
      }
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps.message && newProps.message.content) {
      this.setState({ showModal: true, message: newProps.message });
      this.props.resetInformationMessage();
    }
  }

  componentDidMount() {
    document.title = 'Contact';
  }
  toggle = () => {
    this.setState({
      showModal: !this.state.showModal
    });
    if (this.state.message.header !== 'Error') {
      this.props.resetPage();
    }
  };

  render() {
    return (
      <div className="d-flex justify-content-center align-items-center m-md-5">
        <div className="d-flex flex-row justify-content-center align-items-center containerApp w-100">
          <div className="d-flex flex-column medium-width h-100 w-100">
            {FormArea(this.props)}
          </div>
        </div>
        <Modal
          isOpen={this.state.showModal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader
            toggle={this.toggle}
            className={
              this.state.message.header === 'Error'
                ? 'text-danger'
                : 'text-success'
            }
          >
            {this.state.message.header}
          </ModalHeader>
          <ModalBody>
            <label className={'text-dark'}>{this.state.message.content}</label>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>
              OK
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const contactReduxForm = reduxForm({
  form: formName
})(Contact);

const mapStateToProps = state => {
  return {
    formValues: getFormValues(formName)(state),
    message: state.contact.message
  };
};

const mapDispatchToProps = dispatch => ({
  sendMessage: data => dispatch(ContactActions.sendMessageRequest(data)),
  resetPage: data => dispatch(reset(formName)),
  resetInformationMessage: () =>
    dispatch(ContactActions.showInformationModal({}))
});

const ContactForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoadingContainer(['SEND_MESSAGE'])(contactReduxForm));

export default ContactForm;
