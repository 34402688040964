import React from 'react';
import AOS from 'aos';
import cashGameIllustration from '../../assets/img/CGTour_Flyer.png';
import downloadIcon from '../../assets/img/Download_icon.png';
import joinIcon from '../../assets/img/Join_icon.png';
import notificationIcon from '../../assets/img/Notification_icon.png';
import profileIcon from '../../assets/img/Profile_icon.png';
import PatrickImage from '../../assets/img/Guest_PA.png';
import YohViralImage from '../../assets/img/Guest_YoHViral.png';
import cashGameParis from '../../assets/img/CGTour-Paris.png';

// import { title, separator, displayText } from './Styles.module.scss';

//* ******************** */
// COMPONENTS PROPS */
//* ******************** */

//* ******************** */
// COMPONENTS STATE */
//* ******************** */

class CashGameTour extends React.Component {
  constructor(props) {
    super(props);
    AOS.init();
  }

  // Component Will Receive Props
  componentWillReceiveProps() {
    AOS.refresh();
  }

  // Component Did Mount
  componentDidMount() {
    document.title = 'Flop Cash Game Tour';
  }
  //* ******************** */
  // USER ACTIONS */
  //* ******************** */

  //* ******************** */
  // RENDER */
  //* ******************** */

  render() {
    return (
      <div className="d-flex flex-fill flex-column justify-content-start align-items-start m-2 m-md-5 containerApp">
        {/***************** PARIS 2020  *********************/}
        <div className="d-flex flex-fill justify-content-center align-items-center w-100 mb-3">
          <h2>2nd Edition</h2>
        </div>
        <div
          className="d-none d-md-flex flex-row justify-content-center align-items-start"
          data-aos="fade-left"
          data-aos-offset="50"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <div className="d-flex flex-column justify-content-center align-items-start ml-2">
            <h3 className="text-left"> Overview </h3>
          </div>
        </div>
        <hr className="d-none d-md-flex weopt" />
        <div className="d-flex flex-wrap flex-fill flex-row w-100 justify-content-between align-items-start mt-2 m-3 m-xl-5">
          <div
            style={{ position: 'relative' }}
            className="d-none d-xl-flex flex-fill col-xl-4 flex-column justify-content-center align-items-end p-2"
            data-aos="fade-up"
            data-aos-offset="50"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            <img
              src={cashGameParis}
              width="100%"
              alt="CashGame-Illustration"
              class="rounded"
            />
          </div>
          <div className="d-flex flex-fill col-lg-12 col-xl-8 flex-column justify-content-start align-items-start p-3">
            <div
              className="d-flex flex-row justify-content-start align-items-center"
              data-aos="fade-left"
              data-aos-offset="50"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <div className="d-flex flex-column justify-content-start align-items-start">
                <h4>
                  Welcome to the 2nd edition of First Land Of Poker Cash Game
                  Tour
                </h4>
                <hr className="d-flex d-md-none weopt" />
                <p className="mt-2 text-justify">
                  We’re thrilled to announce that the FLOP Cash Game Tour - 2nd
                  edition, will take place in Paris at Paris Elysées Club in the
                  most beautiful avenue in the World! Join us on February 15th &
                  16th to challenge the biggest online poker winner in history,
                  the high stakes Poker After Dark cash games iconic figure,
                  Patrik Antonius with limits starting from 100 euros for 2/4
                  NLH & PLO. Meet other famous players from the french poker
                  community, like the youtuber and streamer Yohviral.
                </p>
                <p>
                  First Land Of Poker team is happy to invite you to challenge
                  the best Poker Players in a friendly atmosphere, with
                  affordable buy-ins, and lot of surprises.
                </p>
              </div>
            </div>
            <div
              className="d-flex flex-row justify-content-start align-items-center mt-4"
              data-aos="fade-left"
              data-aos-offset="50"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <div className="d-flex flex-column justify-content-start align-items-start">
                <h4>
                  You want to be part of FLOP Cash Game Tour 2nd edition ? Then
                  register through First Land Of Poker Application by following
                  these steps.
                </h4>
                <hr className="d-flex d-md-none weopt" />
                <div className="ml-2 mt-3">
                  <div className="d-flex flex-row justify-content-start align-items-center">
                    <img
                      src={downloadIcon}
                      alt="download icon"
                      width="32px"
                      height="32px"
                    />
                    <span className="ml-2 text-justify">
                      Download First Land Of Poker Application available in
                      Apple Store and Play Store
                    </span>
                  </div>
                  <div className="d-flex flex-row justify-content-start align-items-center mt-3">
                    <img
                      src={profileIcon}
                      alt="download icon"
                      width="32px"
                      height="32px"
                    />
                    <span className="ml-2 text-justify">
                      Create your FLOP account and set your Game Preferences
                    </span>
                  </div>
                  <div className="d-flex flex-row justify-content-start align-items-center mt-3">
                    <img
                      src={joinIcon}
                      alt="download icon"
                      width="32px"
                      height="32px"
                    />
                    <span className="ml-2 text-justify">
                      Join the game that you want to play in the Casino
                      Directory section by selecting “Paris Elysées Club”
                    </span>
                  </div>
                  <div className="d-flex flex-row justify-content-start align-items-center mt-3">
                    <img
                      src={notificationIcon}
                      alt="download icon"
                      width="32px"
                      height="32px"
                    />
                    <span className="ml-2 text-justify">
                      You will be notified for any change regarding the game
                      that you joined
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Second Part */}
        <div className="d-flex flex-fill flex-column justify-content-start align-items-start m-2 m-md-5 containerApp">
          <div
            className="d-none d-md-flex flex-row justify-content-center align-items-start"
            data-aos="fade-left"
            data-aos-offset="50"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            <div className="d-flex flex-column justify-content-center align-items-start ml-2">
              <h3 className="text-left"> Offered games & rake </h3>
            </div>
          </div>
          <hr className="d-none d-lg-flex weopt" />
          <div className="d-flex flex-column flex-fill flex-row w-100 justify-content-between align-items-start mt-2 m-3 m-xl-5">
            <div
              className="d-flex flex-row justify-content-start align-items-center mt-4"
              data-aos="fade-left"
              data-aos-offset="50"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <div className="d-flex flex-column justify-content-start align-items-start">
                <h4>Offered games</h4>
                <hr className="d-flex d-md-none weopt" />
                <p className="mt-2">
                  NLH : 2/4, 5/5, 5/10, 10/20, 25/25, 50/100 <br />
                  PLO : 2/4, 5/5, 5/10, 10/20, 25/25, 50/100
                </p>
              </div>
            </div>
            <div
              className="d-flex flex-row justify-content-start align-items-center mt-4"
              data-aos="fade-left"
              data-aos-offset="50"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <div className="d-flex flex-column justify-content-start align-items-start">
                <h4>Rake</h4>
                <hr className="d-flex d-md-none weopt" />
                <div className="d-block d-lg-flex flex-row justify-space-between align-items-start">
                  <div className="d-block d-lg-flex flex-fill flex-column">
                    <p>4% for NLH with the following caps:</p>
                    <ul className="pl-1" style={{ listStyle: 'none' }}>
                      <li>- 2/4 Min Buy-in 100 euros no Cap</li>
                      <li>- 5/5 Min Buy-in 250 euros Max Buy-In 2 500 euros no Cap</li>
                      <li>- 5/10 Min Buy-in 500 euros Cap 75 euros</li>
                      <li>- 10/20 Min Buy-in 1 000 euros Max Buy-In 10 000 euros Cap 100 euros</li>
                      <li>- 20/40 Min Buy-in 2 000 euros Max Buy-In 20 000 euros Cap 200 euros</li>
                      <li>- 25/50 Min Buy-in 5 000 euros Max Buy-In 50 000 euros Cap 250 euros</li>
                      <li>- 50/100 Min Buy-in 10 000 euros Max Buy-In 100 000 euros Cap 300 euros</li>
                      <li>- 100/200 Min Buy-in 20 000 euros Max Buy-In 200 000 euros Cap 600 euros</li>
                      <li>- 250/500 Min Buy-in 50 000 euros Max Buy-In 500 000 euros Cap 500 euros</li>
                    </ul>
                  </div>
                  <div className="d-block d-lg-flex flex-fill flex-column pl-0 pl-lg-5">
                    <p>2% for PLO with following caps:</p>
                    <ul className="pl-1" style={{ listStyle: 'none' }}>
                      <li>- 2/4 Min Buy-in 100 euros no Cap</li>
                      <li>- 5/5 Min Buy-in 250 euros Max Buy-In 2500 euros no Cap</li>
                      <li>- 5/10 Min Buy-in 500 euros Cap 75 euros</li>
                      <li>- 10/20 Min Buy-in 1 000 euros Max Buy-In 10 000 euros Cap 100 euros</li>
                      <li>- 20/40 Min Buy-in 2 000 euros Max Buy-In 20 000 euros Cap 200 euros</li>
                      <li>- 25/50 Min Buy-in 5 000 euros Max Buy-In 50 000 euros Cap 250 euros</li>
                      <li>- 50/100 Min Buy-in 10 000 euros Max Buy-In 100 000 euros Cap 300 euros</li>
                      <li>- 100/200 Min Buy-in 20 000 euros Max Buy-In 200 000 euros Cap 600 euros</li>
                      <li>- 250/500 Min Buy-in 50 000 euros Max Buy-In 500 000 euros Cap 500 euros</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Second Part */}
        <div className="d-flex flex-fill flex-column justify-content-start align-items-start m-3 m-xl-5">
          <h2
            className="d-flex text-left titleApp"
            data-aos="fade-left"
            data-aos-offset="50"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            Meet our Special Guests
          </h2>
          <hr className="d-flex weopt" />
          <div className="d-block d-md-flex flex-fill flex-row justify-content-start align-items-start justify-content-start p-xl-3 mt-5">
            <div className="d-block d-md-flex flex-fill mr-0 mr-md-3 position-relative">
              <img
                height="400px"
                alt="flop-player-illustration"
                src={PatrickImage}
                data-aos="fade-up"
                data-aos-offset="50"
                data-aos-delay="50"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
              />
            </div>
            <div className="d-block d-md-flex flex-fill mt-3 mt-md-0 ml-0 ml-md-3 position-relative">
              <img
                className="d-block d-md-flex"
                height="400px"
                alt="flop-player-illustration"
                src={YohViralImage}
                data-aos="fade-up"
                data-aos-offset="50"
                data-aos-delay="50"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
              />
            </div>
          </div>
        </div>
        {/***************** CANNES 2019  *********************/}
        {/* Fist Part */}
        <div className="d-flex flex-fill justify-content-center align-items-center w-100 mb-3 mt-5">
          <h2>1st Edition</h2>
        </div>
        <div
          className="d-none d-md-flex flex-row justify-content-center align-items-start"
          data-aos="fade-left"
          data-aos-offset="50"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <div className="d-flex flex-column justify-content-center align-items-start ml-2">
            <h3 className="text-left"> Overview </h3>
          </div>
        </div>
        <hr className="d-none d-md-flex weopt" />
        <div className="d-flex flex-wrap flex-fill flex-row w-100 justify-content-between align-items-start mt-2 m-3 m-xl-5">
          <div
            style={{ position: 'relative' }}
            className="d-none d-xl-flex flex-fill col-xl-4 flex-column justify-content-center align-items-end p-2"
            data-aos="fade-up"
            data-aos-offset="50"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            <img
              src={cashGameIllustration}
              width="100%"
              alt="CashGame-Illustration"
              class="rounded"
            />
          </div>
          <div className="d-flex flex-fill col-lg-12 col-xl-8 flex-column justify-content-start align-items-start p-3">
            <div
              className="d-flex flex-row justify-content-start align-items-center"
              data-aos="fade-left"
              data-aos-offset="50"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <div className="d-flex flex-column justify-content-start align-items-start">
                <h4>
                  Welcome to the first edition of First Land Of Poker Cash Game
                  Tour
                </h4>
                <hr className="d-flex d-md-none weopt" />
                <p className="mt-2 text-justify">
                  The inaugural First Land of Poker Cash Game Tour event will
                  take place on the Côte d'Azur in the glamorous city of Cannes.
                  Join professional players Patrik Antonius and Johan Guilbert
                  (AKA YoH Viral) on the 9th & 10th of November in the Poker
                  Room Casino La Croisette Cannes, for a weekend of cash game
                  action. Games offered include NLH & PLO, from 5/10 and
                  upwards.
                </p>
                <p>
                  First Land Of Poker team is happy to invite you to challenge
                  the best Poker Players in a friendly atmosphere, with
                  affordable buy-ins, and lot of surprises.
                </p>
              </div>
            </div>
            <div
              className="d-flex flex-row justify-content-start align-items-center mt-4"
              data-aos="fade-left"
              data-aos-offset="50"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <div className="d-flex flex-column justify-content-start align-items-start">
                <h4>
                  You want to be part of the Edition 1 of FLOP Cash Game Tour?
                  Then register through First Land Of Poker Application by
                  following these steps.
                </h4>
                <hr className="d-flex d-md-none weopt" />
                <div className="ml-2 mt-3">
                  <div className="d-flex flex-row justify-content-start align-items-center">
                    <img
                      src={downloadIcon}
                      alt="download icon"
                      width="32px"
                      height="32px"
                    />
                    <span className="ml-2 text-justify">
                      Download First Land Of Poker Application available in
                      Apple Store and Play Store
                    </span>
                  </div>
                  <div className="d-flex flex-row justify-content-start align-items-center mt-3">
                    <img
                      src={profileIcon}
                      alt="download icon"
                      width="32px"
                      height="32px"
                    />
                    <span className="ml-2 text-justify">
                      Create your FLOP account and set your Game Preferences
                    </span>
                  </div>
                  <div className="d-flex flex-row justify-content-start align-items-center mt-3">
                    <img
                      src={joinIcon}
                      alt="download icon"
                      width="32px"
                      height="32px"
                    />
                    <span className="ml-2 text-justify">
                      Join the game that you want to play in the Casino
                      Directory section by selecting “Casino Barrière de Cannes
                      Croisette”
                    </span>
                  </div>
                  <div className="d-flex flex-row justify-content-start align-items-center mt-3">
                    <img
                      src={notificationIcon}
                      alt="download icon"
                      width="32px"
                      height="32px"
                    />
                    <span className="ml-2 text-justify">
                      You will be notified for any change regarding the game
                      that you joined
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Second Part */}
        <div className="d-flex flex-fill overflow-hidden flex-column justify-content-start align-items-start m-2 m-md-5 containerApp">
          <div
            className="d-none d-md-flex flex-row justify-content-center align-items-start"
            data-aos="fade-left"
            data-aos-offset="50"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            <div className="d-flex flex-column justify-content-center align-items-start ml-2">
              <h3 className="text-left"> Offered games & rake </h3>
            </div>
          </div>
          <hr className="d-none d-lg-flex weopt" />
          <div className="d-flex flex-column flex-fill flex-row w-100 justify-content-between align-items-start mt-2 m-3 m-xl-5">
            <div
              className="d-flex flex-row justify-content-start align-items-center mt-4"
              data-aos="fade-left"
              data-aos-offset="50"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <div className="d-flex flex-column justify-content-start align-items-start">
                <h4>Offered games</h4>
                <hr className="d-flex d-md-none weopt" />
                <p className="mt-2">
                  NLH : 5/10, 10/20, 25/50 <br />
                  PLO : 5/10, 10/20, 25/50
                </p>
              </div>
            </div>
            <div
              className="d-flex flex-row justify-content-start align-items-center mt-4"
              data-aos="fade-left"
              data-aos-offset="50"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <div className="d-flex flex-column justify-content-start align-items-start">
                <h4>Rake</h4>
                <hr className="d-flex d-md-none weopt" />
                <div className="d-block d-lg-flex flex-row justify-space-between align-items-start">
                  <div className="d-block d-lg-flex flex-fill flex-column">
                    <p>5% for NLH with the following caps:</p>
                    <ul className="pl-1" style={{ listStyle: 'none' }}>
                      <li>- Min Buy-in 500 euros Cap 75 euros</li>
                      <li>- Min Buy-in 1000 euros Cap 1000 euros</li>
                      <li>- Min Buy-in 2000 euros Cap 200 euros</li>
                      <li>- Min Buy-in 3000 euros Cap 300 euros</li>
                      <li>- Min Buy-in 4000 euros Cap 400 euros</li>
                      <li>- Min Buy-in 5000 euros Cap 250 euros</li>
                    </ul>
                  </div>
                  <div className="d-block d-lg-flex flex-fill flex-column pl-0 pl-lg-5">
                    <p>2% for PLO with following caps:</p>
                    <ul className="pl-1" style={{ listStyle: 'none' }}>
                      <li>- Min Buy-in 500 euros Cap 75 euros</li>
                      <li>- Min Buy-in 1000 euros Cap 1000 euros</li>
                      <li>- Min Buy-in 2000 euros Cap 200 euros</li>
                      <li>- Min Buy-in 3000 euros Cap 300 euros</li>
                      <li>- Min Buy-in 4000 euros Cap 400 euros</li>
                      <li>- Min Buy-in 5000 euros Cap 250 euros</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Second Part */}
        <div className="d-flex flex-fill flex-column justify-content-start align-items-start m-3 m-xl-5">
          <h2
            className="d-flex text-left titleApp"
            data-aos="fade-left"
            data-aos-offset="50"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            Meet our Special Guests
          </h2>
          <hr className="d-flex weopt" />
          <div className="d-block d-md-flex flex-fill flex-row justify-content-start align-items-start justify-content-start p-xl-3 mt-5">
            <div className="d-block d-md-flex flex-fill mr-0 mr-md-3 position-relative">
              <img
                height="400px"
                alt="flop-player-illustration"
                src={PatrickImage}
                data-aos="fade-up"
                data-aos-offset="50"
                data-aos-delay="50"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
              />
            </div>
            <div className="d-block d-md-flex flex-fill mt-3 mt-md-0 ml-0 ml-md-3 position-relative">
              <img
                className="d-block d-md-flex"
                height="400px"
                alt="flop-player-illustration"
                src={YohViralImage}
                data-aos="fade-up"
                data-aos-offset="50"
                data-aos-delay="50"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CashGameTour;
