// @flow
import {
  createReducer,
  createActions,
  Types as ReduxSauceTypes
} from 'reduxsauce';
import Immutable from 'seamless-immutable';
import _ from 'lodash';

const GLOBAL_ERROR =
  'We are sorry but an error has occurred. please try again momentarily';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  // Errors
  displayError: ['message'], // Display Error to the user
  globalError: []
});

export const ErrorTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({});

/* ------------- Reducers ------------- */
// We store here each Action with a loading
export const errorReducer = (state: any, action: any) => {
  const { type, payload } = action;
  const matches = /(.*)_(REQUEST|FAILURE_RESPONSE)/.exec(type);

  // not a *_REQUEST / *_FAILURE actions, so we ignore them
  if (!matches) return state;

  const [, requestName, requestState] = matches;
  return state.merge({
    [requestName]: requestState === 'FAILURE_RESPONSE' ? payload : ''
  });
};

// Handling an Error
export const displayErrorReducer = (state: any, action: any) =>
  state.merge({ displayError: action.message });

// Handling Global Error
export const globalErrorReducer = (state: any, action: any) =>
  state.merge({ displayError: GLOBAL_ERROR });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [ReduxSauceTypes.DEFAULT]: errorReducer,
  [Types.DISPLAY_ERROR]: displayErrorReducer,
  [Types.GLOBAL_ERROR]: globalErrorReducer
});

/* ------------- Selectors ------------- */

// Selector For Error
export const createErrorMessageSelector = (actions: any) => (state: any) =>
  // returns the first error messages for actions
  // * We assume when any request fails on a page that
  //   requires multiple API calls, we shows the first error
  _(actions)
    .map(action => _.get(state, `${action}`))
    .compact()
    .first() || '';
