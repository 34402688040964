import React from 'react';
import { memberContainer } from './Member.module.scss';

// Member Comp
const Member = ({ member, className, onClickMember, ...props }) => (
  <div
    onClick={onClickMember}
    {...props}
    className={`${className} ${memberContainer} d-flex flex-column justify-content-center align-items-center`}
  >
    <img
      className="rounded-circle"
      width="200px"
      src={member.srcImage}
      alt={member.altImage}
    />
    <p className="mt-2">
      {member.firstName} {member.lastName}
    </p>
    <p className="text-center">{member.jobTitle}</p>
  </div>
);

export default Member;
