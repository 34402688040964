/* eslint-disable */
export default {
  // * TITLE
  AGREEMENT_PAGE: 'TERM OF USE',
  // ***************
  // * FIRST PART
  // ***************
  AGREEMENT_TITLE: 'MOBILE APP TERMS AND CONDITIONS OF USE',
  AGREEMENT_PARAG_1:
    'These terms and conditions (hereinafter, the “Terms”) apply to and govern User’s access and use of WEOPT’ Services. \n' +
    'By downloading, browsing, accessing or using the Service, User confirm his acceptance to be bound by the Terms.\n' +
    'If User do not agree to the Terms, he must immediately uninstall the Service or discontinue its use.\n' +
    'Continued use of the Service will constitute an acceptance of the Terms.\n',

  // ***************
  // ABOUT WEOPT
  // ***************
  ABOUT_TITLE: '1.	About WEOPT',
  ABOUT_TEXT:
    'WEOPT is a company specialized in digital IT solutions, technology and gaming focusing on poker having its headquarters located in Monaco 10, Boulevard Princesse Charlotte, Le Windsor Bureaux (98000 – MONACO) which has developed the Application directed at Players and Casinos.',

  // ***************
  // Definition
  // ***************
  DEFINITION_TITLE: '2.	Definitions',
  DEFINITION_PARAG_TEXT:
    'In the Terms, the following capitalised terms shall have the following meanings, except where the context otherwise requires:',
  DEFINITION_1_TEXT:
    '- Account means the account created by the User of the Application.',
  DEFINITION_2_TEXT:
    '- Application means the FLOP mobile application developed by WEOPT and provided on a SAAS basis.',
  DEFINITION_3_TEXT:
    '- Players means the non-professional users of the Application. For all intents and purposes, a non-professional is dubbed as any physical or legal person that acts for purposes that are not part of its commercial, industrial, artisanal, liberal or agricultural activity',
  DEFINITION_4_TEXT:
    '- Casino means the professional users of the Web Platform.',
  DEFINITION_5_TEXT:
    '- Device means without limitation a cellular phone, a tablet, a PC or any other type of mobile device now existing.',
  DEFINITION_6_TEXT:
    '- Service means the Application and the Web Platform provided by WEOPT regardless of the medium including User’s Device or PC.',
  DEFINITION_7_TEXT:
    '- User means the general qualification of “Player”, “Casino” as well as “Online Gambling Operator” altogether.',
  DEFINITION_8_TEXT:
    '- Web Platform means the platform-based service “FLOP PMS” accessible to Casinos and Online Gambling Operators from www.pms.firstlandofpoker.com.',

  // ***************
  // 3.	Acceptance of the Terms
  // ***************
  ACCEPT_TITLE: '3.	Acceptance of the TermsAcceptance of the Terms',
  ACCEPT_1_TEXT:
    '3.1.	The Terms should be read by User prior to the use of the Service and constitutes a legally binding agreement between User and WEOPT.',
  ACCEPT_2_TEXT:
    '3.2.	If User do not agree to all of these Terms, then he may not access the Service.',
  ACCEPT_3_TEXT:
    '3.3.	User acknowledges that WEOPT reserves the right at its sole discretion to change or update the Terms. If you continue using the Service, User will be bound by any or all modifications made to it.',

  //******************* */
  // 4 LICENCE
  // *******************/
  LICENCE_TITLE: '4.	License to use for the Players',
  LICENCE_1_PARAG:
    '4.1.	Subject to the Terms contained herein WEOPT grants the Player a free of charge, non-exclusive, non-transferable, personal, revocable, license to install and use the Application, including text, graphics, images, photographs, videos, data, displays, illustrations and other content associated with the Application on Players’ Device or PC.',
  LICENCE_2_PARAG:
    '4.2.	The license granted by WEOPT does not provide to the Player any of the following rights:',
  LICENCE_1_TEXT: 'i. To sell, resell or to commercially use the Application;',
  LICENCE_2_TEXT:
    'ii. To copy, to reproduce, to distribute, to publicly perform or to publicly display the Application;',
  LICENCE_3_TEXT:
    'iii.	To modify the Application, to remove any proprietary rights notices or making, or otherwise to make any derivative use of the Application;',
  LICENCE_4_TEXT:
    'iv. To use the data mining, robots, or similar data gathering or extraction methods of the Application;',
  LICENCE_5_TEXT:
    'v. To use the Application other than for its intended purpose.',

  //******************* */
  // 5 LICENCE PRO
  // *******************/
  LICENCEPRO_TITLE:
    '5.	License to use for the Casinos and Online Gambling Operators',
  LICENCEPRO_1_PARAG:
    'The licenses granted by WEOPT to Casinos and Online Gambling Operators is the perfect continuum of a previous agreement signed between WEOPT and a Casino or an Online Gambling Operator taken as a third-party. ',
  LICENCEPRO_2_PARAG:
    'Subject to the Terms contained herein WEOPT grants the Casino and/or the Online Gambling Operator a non-exclusive, non-transferable, personal, revocable, license to use the Web Platform, including text, graphics, images, photographs, videos, data, displays, illustrations and other content associated with the Web Platform.',
  LICENCEPRO_3_PARAG:
    'The license does not provide to Casino and/or the Online Gambling Operator any of the following rights:',
  LICENCEPRO_1_TEXT:
    'To sell, to resell or to commercially use the Web Platform;',
  LICENCEPRO_2_TEXT:
    'To copy, to reproduce, to publicly display the Web Platform;',
  LICENCEPRO_3_TEXT:
    'To modify the Web Platform, to remove any proprietary rights notices or making, or otherwise to make any derivative uses of the Web Platform;',
  LICENCEPRO_4_TEXT:
    'To use the data mining, robots, or similar data gathering or extraction methods of the Web Platform;',
  LICENCEPRO_5_TEXT:
    'To use the Web Platform other than for its intended purpose.',

  //******************** */
  // 6 PROHIBITION
  //******************* */
  PROHIB_TITLE: '6.	Prohibited Content and prohibited use of the Service',
  PROHIB_1_PARAG:
    '6.1.	User agrees to use the Service in compliance with the Terms and with Monegasque Laws.',
  PROHIB_2_PARAG:
    '6.2.	User will not violate any applicable Law, any intellectual property of third-party rights or commit any tort and is solely responsible for his conduct while using the Service.',
  PROHIB_3_PARAG:
    '6.3.	In connection with the use of the Service User will not:',
  PROHIB_1_TEXT:
    'i. Engage in any harassing, threatening, intimidating, stalking conduct or in anything that infringes or violates the right of others;',
  PROHIB_2_TEXT:
    'ii. Do anything unlawful, illegal or which is fraudulent, malicious;',
  PROHIB_3_TEXT:
    'iii. Impersonate another person or log into an account which he has no authorized access;',
  PROHIB_4_TEXT:
    'iv. Use or attempt to use another User’s account without prior authorization of the User;',
  PROHIB_5_TEXT:
    'v. Use the Service in a manner that could interfere with, disrupt, negatively affect or inhibit other Users or that could damage, disable or impair the functioning of the Service in any manner;',
  PROHIB_6_TEXT: 'vi. Use the Service for any unauthorized purpose;',
  PROHIB_7_TEXT:
    'vii. Reverse engineer, decompile or disassemble any aspect of the Service or do anything that may discover source code or bypass or limit access to any part of the Service;',
  PROHIB_8_TEXT:
    'viii. Knowingly introduce viruses or other malicious material.',

  //******************** */
  // 7 PLAYERS
  //******************* */
  PLAYERS_TITLE: '7.	Player’s Commitments',
  PLAYERS_1_PARAG: 'Please note that the Application is not for use:',
  PLAYERS_2_PARAG: '-	by individuals under the age of 18 years old;',
  PLAYERS_3_PARAG:
    'WEOPT is not able to verify the legality of the Application by the Player in each jurisdiction.',
  PLAYERS_4_PARAG:
    'Player or legal tutor if applicable - undertakes the responsibility to ensure that his use of the Application is lawful.',

  //******************** */
  // 8 ACCOUNT
  //******************* */
  ACCOUNT_TITLE: '8.	Right to terminate an account',
  ACCOUNT_1_PARAG: 'User may terminate his account at any time.',
  ACCOUNT_2_PARAG:
    'For any enquiries regarding the termination of an account, User may refer to our FAQ. ',

  //******************** */
  // 9 PROPERTY
  //******************* */
  PROPERTY_TITLE: '9.	Intellectual Property Rights',
  PROPERTY_1_PARAG:
    '9.1.	The Service is protected by the copyright Law of Monaco (Act n°491 dated November 24th,1948 for the Protection of Artistic and Literary Works). \nWEOPT retains all right, title, and interest in the Service, including all intellectual property rights associated with it.',
  PROPERTY_2_PARAG:
    '9.2.	User have no intellectual property rights in the Service and WEOPT reserves all rights not expressly granted to him.',
  PROPERTY_3_PARAG:
    '9.3.	User is granted by WEOPT a license as provided in Article 4 and in Article 5.',

  //******************** */
  // 10 PRIVACY
  //******************* */
  PRIVACY_TITLE: '10.	Privacy',
  PRIVACY_1_PARAG:
    '10.1.	Private policy matters to WEOPT. Through User’s use of the Application WEOPT will collect and use his ID (name, family-name), store his username, language selection, country selection and location data.',
  PRIVACY_2_PARAG:
    '10.2.	 WEOPT comply with the terms of applicable data protections regulations when processing User personal information in compliance with its privacy policy.',
  PRIVACY_3_PARAG:
    '10.3.	For the purposes of the privacy policy the data controller is Mr. Gélibert who can be contacted r.gelibert@we-opt.com.',
  //*******************  */
  // 11 DISCLAIMER LIABILITY */
  //*******************  */
  DISCLAIMER_TITLE: '11.	Disclaimer of Liability',
  DISCLAIMER_1_PARAG:
    'WEOPT will not be liable for any indirect, incidental, special, damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data or other intangible losses resulting from:',
  DISCLAIMER_1_TEXT:
    'i.	User’s use of the Application or inability to use the Service;',
  DISCLAIMER_2_TEXT:
    'ii.	User’s access to or inability to access to the Service;',
  DISCLAIMER_3_TEXT:
    'iii.	The conduct or content of other Users or third parties of the Service;',

  //*******************  */
  // 12.	Disclaimer of Warranty*/
  //*******************  */
  DISCLAIMER_WARRANTY_TITLE: '12.	Disclaimer of Warranty',
  DISCLAIMER_WARRANTY_1_PARAG:
    '12.1.	User understands that the Service is provided “as is” and “as available” without express or implied warranty or condition of any kind given by WEOPT.',
  DISCLAIMER_WARRANTY_2_PARAG:
    '12.2.	User uses the Service at his own risks to the fullest extent permitted by Monegasque Law and by the Terms.\n Therefore, without limitation, WEOPT does not give any warranty as to the accuracy, the suitability, the completeness, the performance of the Service and do not warrant either that the Service is free of malware or other harmful components.',
  DISCLAIMER_WARRANTY_3_PARAG:
    '12.3.	While WEOPT endeavour to ensure that the Service is normally available 24 hours a day, it shall not be liable if for any reason the Service is not available at any time or for any period.',
  DISCLAIMER_WARRANTY_4_PARAG:
    '12.4.	User undertakes that access to the Service may be suspended temporarily from to time to time and without notice in the case of system failure, maintenance or repair or for any reason beyond WEOPT control or if necessary. \n  WEOPT disclaims any responsibility for any loss which User may be suffered or incurred to User as a result of the suspension or the interruption of the Service.	',

  //********************** */
  // 13 JURISDICTION
  //*******************  */
  JURISDICTION_TITLE: '13.	Governing Law & Jurisdiction.',
  JURISDICTION_1_PARAG:
    '13.1.	The Terms are governed by the laws of Monaco. Any dispute, controversy, or claim of whatever nature arising out of or in any way relating to the Terms and the rights created hereby shall be governed, interpreted and construed by, under or pursuant to the Laws of Monaco, without reference to conflict of laws principles. ',
  JURISDICTION_2_PARAG:
    '13.2.	The validity of this governing law clause is not contested, and the Terms shall be binding to the benefit of the parties hereto and their successors or assigns. ',
  JURISDICTION_3_PARAG:
    '13.3.	The parties agree that the Courts of Monaco will have exclusive jurisdiction to settle any disputes or claims.',

  //********************** */
  // 14 SUSPENSION
  //*******************  */
  SUSPENSION_TITLE: '14.	Suspension and Termination',
  SUSPENSION_1_PARAG:
    '14.1.	WEOPT shall be entitled, immediately or at any time, to suspend User’s use of the Service in the following cases:',
  SUSPENSION_1_TEXT: '-	If User commits any breach of the Terms;',
  SUSPENSION_2_TEXT:
    '-	If WEOPT suspects on reasonable grounds, that User have, might or will commit a breach of the Terms;',
  SUSPENSION_3_TEXT:
    '-	If WEOPT suspects on reasonable grounds that User may have committed or be committing any fraud against it.',
  SUSPENSION_2_PARAG:
    '14.2.	In case WEOPT suspends the Service, WEOPT shall refuse to restore the Service on User devise until WEOPT receives an insurance from User that there will be no further breach of the provisions of the Terms. ',
  SUSPENSION_3_PARAG:
    '14.3.	User may terminate or suspend his use of the Service without prior notice and at any time without any liability. Upon any termination User rights to use the Service will end. As such, User must stop using the Service. Concerning the Application Player must delete it from his device.',

  //********************** */
  // 15 SEVERABILITY
  //*******************  */
  SEVERABILITY_TITLE: '15.	Severability',
  SEVERABILITY_PARAG:
    'If any provision of the Terms is held invalid, illegal or unenforceable the remaining provisions of the Terms will remain in full force, provided that the essential terms and conditions remain valid, binding and enforceable.  '
};
