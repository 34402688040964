import React from 'react';
import AOS from 'aos';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Member from '../components/Member';
import {
  PatrickSquare,
  BenoitSquare,
  CorentinSquare,
  IdrissSquare,
  NolanSquare,
  RemiSquare,
  Alexis
} from '../../../assets';
// import { title, separator, displayText } from './Styles.module.scss';

const members = [
  {
    id: 0,
    altImage: 'Patrik Antonius Picture',
    srcImage: PatrickSquare,
    firstName: 'Patrik',
    lastName: 'Antonius',
    jobTitle: 'Co-founder – Brand Ambassador',
    description:
      'Patrik Antonius, one of the most successful Poker player in the World, and the All time biggest winner in Online Poker. He is the co-founder of WeOpt, and its Brand Ambassador. Patrik is bringing his deep knowledge of the Poker industry to WeOpt in order to build the best digital solutions for the Poker Community. He co-funded WeOpt with Idriss BELLAMINE with one goal, changing the Poker industry to better by offering players the best Poker experience.',
    linkedinLink: 'https://www.linkedin.com/in/patrik-antonius-83b581158/'
  },
  {
    id: 1,
    altImage: 'Idriss Bellamine Picture',
    srcImage: IdrissSquare,
    firstName: 'Idriss',
    lastName: 'Bellamine',
    jobTitle: 'Co-founder – COO – Head of Business Analysis',
    description:
      'Idriss Bellamine is the co-founder, Chief Operating Officer (COO) and Head of IT Business Analysis of WeOpt. He started working on the idea of WeOpt, together with his partner Patrik Antonius, in late 2017. Idriss sets the operational strategy and product business logic for the company to provide poker players with unique poker experiences, to achieve WeOpt goals and mission of creating a new world where poker players and operators are one unique community.' +
      'Originally from Morocco, Idriss graduated from a French Engineering School where he received two master’s degrees in applied mathematics and Modeling, and Finance & Insurance. Prior to co-founding WeOpt, he was a Senior IT Business Analyst at Sopra Steria Group, one of the World’s Leading companies in IT consulting, where he contributed to several projects in Airline industry and the French Social Protection.',
    linkedinLink: 'https://www.linkedin.com/in/idriss-bellamine-a5797648/'
  },
  {
    id: 2,
    altImage: 'Rémi Gelibert Picture',
    srcImage: RemiSquare,
    firstName: 'Rémi',
    lastName: 'Gelibert',
    jobTitle: 'CTO',
    description:
      'Rémi Gilbert is the Chief Technical Officer (CTO) of WeOpt. He Joined WeOpt from the start and set the Technical Architecture for all Products, Tools, Methodologies, and Technical Management. Rémi is an open source / DevOps lover and he worked as technical architect in a French IT-Consulting company for 10 years where he Managed over 200 software engineers. He is a Poker lover, and Soccer addict.',
    linkedinLink:
      'https://www.linkedin.com/in/r%C3%A9mi-g%C3%A9libert-411746ab/'
  },
  {
    id: 3,
    altImage: 'Benoît Machut Picture',
    srcImage: BenoitSquare,
    firstName: 'Benoît',
    lastName: 'Machut',
    jobTitle: 'UX/UI designer',
    description:
      'Benoît Machut is the User Interface and User Experience Designer of WeOpt, a design and tech enthusiast that previously worked as a UX/UI designer for a Monegasque fintech company. Benoît loves stargazing and scale modelling.',
    linkedinLink: 'https://www.linkedin.com/in/benoit-machut-454770b2/'
  },
  {
    id: 4,
    altImage: 'Alexis Picture',
    srcImage: Alexis,
    firstName: 'Alexis',
    lastName: 'Ségura',
    jobTitle: 'Backend Developer Intern',
    description:
      'Alexis is a mid-time intern at Polytec\'Nice Sophia Antipolis. He joined the company in order to learn the Cloud Computing and how to be a good architect on the cloud.',
    linkedinLink: 'https://www.linkedin.com/in/alexis-sgra/'
  },
  {
    id: 5,
    altImage: 'Nolan Potier Picture',
    srcImage: NolanSquare,
    firstName: 'Nolan',
    lastName: 'Potier',
    jobTitle: 'Frontend Software developer',
    description:
      'Nolan is a Software Engineer with a strong technical background and training. Nolan has worked within the travel and tourism industry, he loves new technologies and is always up for new challenges.',
    linkedinLink: 'https://www.linkedin.com/in/nolan-potier-b82a3a57/'
  },
  {
    id: 6,
    altImage: 'Corentin Laithier Picture',
    srcImage: CorentinSquare,
    firstName: 'Corentin',
    lastName: 'Laithier',
    jobTitle: 'Frontend Software developer',
    description:
      'Corentin is a Frontend Developer at WeOpt. He provides high quality coding, contributing to the WeOpt product development with enthusiasm and reliability. Corentin is a Heavy Metal lover and avid guitar player.',
    linkedinLink: 'https://www.linkedin.com/in/corentin-laithier-a497b460/'
  }
];

class Team extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      memberToDisplay: {
        description: '',
        linkedinLink: ''
      }
    };
    AOS.init();
  }

  // Component Will Receive Props
  componentWillReceiveProps() {
    AOS.refresh();
  }

  // Component Did Mount
  componentDidMount() {
    document.title = 'The Team';
  }
  //* ******************** */
  // USER ACTIONS */
  //* ******************** */

  toggleModalMember = member => {
    this.setState({
      memberToDisplay: member,
      modalOpen: !this.state.modalOpen
    });
  };

  //* ******************** */
  // RENDER */
  //* ******************** */

  render() {
    return (
      <div className="d-flex flex-fill flex-column justify-content-start align-items-start m-5 w-75">
        <h2
          className="d-none d-md-flex text-left"
          data-aos="fade-left"
          data-aos-offset="50"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          The Team
        </h2>
        <hr className="d-none d-md-flex weopt" />
        <div className="d-flex flex-wrap flex-fill flex-row justify-content-center justify-content-xl-between align-items-start m-xl-5">
          {members.map(m => (
            <Member
              member={m}
              data-aos="fade-up"
              data-aos-offset="50"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              onClickMember={() => this.toggleModalMember(m)}
            />
          ))}
        </div>
        <Modal
          isOpen={this.state.modalOpen}
          toggle={this.toggleModalMember}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleModalMember} />
          <ModalBody className="d-flex flex-column justify-content-center align-items-center p-4">
            <Member member={this.state.memberToDisplay} />
            <p>{this.state.memberToDisplay.description}</p>
          </ModalBody>
          <ModalFooter className="d-flex flex-column justify-content-center align-items-center">
            <a
              className={`btn btn-primary mt-5`}
              href={this.state.memberToDisplay.linkedinLink}
              rel="noopener noreferrer"
              target="_blank"
            >
              FOLLOW ON LINKEDIN
            </a>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default Team;
