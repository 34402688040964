import { put, call } from 'redux-saga/effects';
import ContactActions from '../redux/ContactRedux';

//* ******************** */
// CONTACT REQUEST       */
//* ******************** */
/**
 * GET GAME DATA
 * @param {*} api : Api To use
 * @param {*} action : Action params
 */
export function* sendMessageRequest(api, action) {
  const { data } = action;
  const DATA_TO_SENT = {
    contactEmail: data.contactEmail,
    contactName: data.contactName,
    content: data.content,
    contactOrigin: 'WEOPT_WEBSITE',
    telephoneNumber: '0650505050',
    subject: data.subject
  };
  const getData = yield call(api.sendMessageRequest, DATA_TO_SENT);
  if (getData.status === 200) {
    yield put(
      ContactActions.showInformationModal({
        header: 'Success',
        content: `Thank you for contacting us! One of our representatives will be in contact with you shortly regarding your inquiry. `
      })
    );
    // Success
    yield put(ContactActions.sendMessageSuccessResponse());
  } else {
    // Error
    yield put(
      ContactActions.showInformationModal({
        header: 'Error',
        content: `We are sorry but an error has occurred. please try again momentarily `
      })
    );
    yield put(ContactActions.sendMessageFailureResponse());
  }
}
