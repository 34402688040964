// @flow
// Api
import apisauce from 'apisauce';

// Type ApiService
export type ApiServiceType = {};

// Default Size Pagination
// const size = 20;

// Base URL
const baseURL: string = process.env.REACT_APP_API_URL;

// ------
// STEP 1
// ------
//
// Create and configure an apisauce-based api object.
//
export const api = apisauce.create({
  // base URL is read from the "constructor"
  baseURL,
  // here are some default headers
  headers: {
  },
  // 10 second timeout...
  timeout: 35000
});

const create = () => {
  // ------
  // STEP 2
  // ------
  //
  // Define some functions that call the api.  The goal is to provide
  // a thin wrapper of the api layer providing nicer feeling functions
  // rather than "get", "post" and friends.
  //
  // I generally don't like wrapping the output at this level because
  // sometimes specific actions need to be take on `403` or `401`, etc.
  //
  // Since we can't hide from that, we embrace it by getting out of the
  // way at this level.
  //
  // let's return back our create method as the default.
  const sendMessageRequest = data =>
    api.post('', data, {
      headers: {
      }
    });
  return {
    // a list of the API functions from step 2
    sendMessageRequest
  };
};

export default {
  create
};
