import React from 'react';
import AOS from 'aos';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

//* ******************** */
// COMPONENTS STATE */
//* ******************** */

class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);
    AOS.init();
  }

  // Component Will Receive Props
  componentWillReceiveProps() {
    AOS.refresh();
  }

  //* ******************** */
  // RENDER */
  //* ******************** */

  render() {
    return (
      <div className="d-flex flex-fill flex-column mt-5 w-75 m-auto">
        <h2 className="mt-5">Privacy Policy</h2>
        <hr className="d-none d-md-flex weopt" />
        <h3 className="mt-3 pb-1">1. Registrar</h3>
        <p className="text-dark ml-4 d-flex flex-fill flex-column">
          <p className="d-flex flex-row">
            <p className="mr-4 align-self-center">
              <FontAwesomeIcon
                color="##2E2E2E"
                icon={faMapMarkerAlt}
                size="2x"
              />
            </p>
            <p>
              WeOpt <br />
              10 Boulevard Princesse Charlotte, Le Windsor Bureaux, <br />
              98000 Monaco
            </p>
          </p>
          <p className="d-flex flex-row">
            <p className="mr-4 align-self-center">
              <FontAwesomeIcon color="#2E2E2E" icon={faEnvelope} size="2x" />
            </p>
            <p>
              {' '}
              <a href="mailto:contact@we-opt.com">contact@we-opt.com</a>{' '}
            </p>
          </p>
        </p>
        <h3 className="mt-3 pb-1">2. Right to privacy</h3>
        <p className="text-dark ml-4">
          We will never collect or publish any personal information without your
          explicit permission and we will never trade this information with
          third parties. Any personal information that you choose to share with
          us will be treated with the highest standards of security and
          confidentiality and our privacy guidelines comply with European laws.
        </p>

        <h3 className="mt-3 pb-1">3. Information we collect</h3>
        <p className="text-dark ml-4">
          We collect information from you when you contact us via contact form.
          When completing a form you may be asked to enter your name, email
          address and other information. <br />
          The information we collect though our form may be used in the
          following ways:
        </p>
        <ul>
          <li>To send relevant emails</li>
        </ul>

        <h2 className="mt-3 pb-1">4. Third party information sharing</h2>
        <p className="text-dark ml-4">
          We will never sell, trade or otherwise share your personally
          identifiable or anonymized information.
        </p>

        <h3 className="mt-3 pb-1">5. How long we store information</h3>
        <p className="text-dark ml-4">
          Customer data will be stored for a maximum of 5 years. You have the
          right to request removal of this data, simply email{' '}
          <a href="mailto:contact@we-opt.com">contact@we-opt.com</a>
        </p>

        <h3 className="mt-3 pb-1">6. Consent and changes</h3>
        <p className="text-dark ml-4">
          By using our contact form you consent to our website’s privacy policy.
          Any updates to the privacy policy will be reflected on this page. This
          page has been last updated in February 2019.
        </p>
      </div>
    );
  }
}

export default PrivacyPolicy;
