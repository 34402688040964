import React, { Component } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import './App.scss';
import Home from './pages/home/Home';
import NotFoundPage from './pages/NotFoundPage';
import Company from './pages/about/company/Company';
import Team from './pages/about/team/Team';
// import JoinUs from './pages/about/joinus/JoinUs';
import PAPC from './pages/events/PAPC';
import CashGameTour from './pages/events/CashGameTour';
import Contact from './pages/contact/Contact';
import Header from './components/Header';
import Footer from './components/Footer';
// import FlopAd from './pages/products/flop-ad/FlopAd';
import Flop from './pages/products/flop/Flop';
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy';
import TermOfUse from './pages/flop-term-of-use/TermOfUse';
import ScrollButton from './components/ScrollButton';

// *
// * DO NOT DISPLAY THE HEADER FOR THE FOLLOWING SCREEN :
// *
const HeaderNotVisible = ['/term-of-use'];

// * Handle the navigation on the website

class App extends Component {
  isVisibleHeader = path => {
    const index = HeaderNotVisible.findIndex(p => p === path);
    if (index === -1) return true;
    return false;
  };

  render() {
    console.log('PROPS SCREEN : ', this.props);
    const PATH_NAME = this.props.location.pathname;
    const visibleHeader = this.isVisibleHeader(PATH_NAME);
    return (
      <div
        className={`d-flex flex-column`}
        style={{ minHeight: '100%', position: 'relative' }}
      >
        <div className={`d-flex flex-fill flex-column rootContainer`}>
          {visibleHeader && <Header />}
          <Switch>
            <Route exact path="/" component={() => <Redirect to="/home" />} />
            <Route exact path="/home" component={Home} />
            <Route exact path="/about/company" component={Company} />
            <Route exact path="/about/team" component={Team} />
            {/* <Route exact path="/about/joinus" component={JoinUs} /> */}
            <Route exact path="/products/flop" component={Flop} />
            {/* <Route exact path="/products/flop-ad" component={FlopAd} /> */}
            <Route exact path="/events/papc" component={PAPC} />
            <Route exact path="/events/cgtour" component={CashGameTour} />
            <Route exact path="/contact/" component={Contact} />
            <Route exact path="/privacy-policy/" component={PrivacyPolicy} />
            <Route exact path="/term-of-use/" component={TermOfUse} />
            <Route component={NotFoundPage} />
          </Switch>
          <Footer />
        </div>
        <div className="triangleTop">
          <svg
            width="382"
            height="800"
            viewBox="0 0 382 800"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 0H382V800C382 800 382 614.5 248 342C118.109 77.8556 0 0 0 0Z"
              fill="url(#paint0_linear)"
            />
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="0"
                y1="0"
                x2="497.749"
                y2="714.992"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#1D222E" />
                <stop offset="1" stopColor="#434652" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="triangleBottom">
          <svg
            width="256"
            height="397"
            viewBox="0 0 256 397"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.75"
              d="M0 397C0 397 116.5 317.5 189.5 220.5C262.5 123.5 256 0.5 256 0.5V397H0Z"
              fill="url(#paint0_linear)"
            />
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="0"
                y1="0.5"
                x2="273.461"
                y2="384.677"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#162C31" />
                <stop offset="1" stopColor="#564160" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <ScrollButton scrollStepInPx="50" delayInMs="16.66" />
      </div>
    );
  }
}

export default withRouter(App);
