// @flow
import { combineReducers } from 'redux';
// $FlowFixMe
import { createBrowserHistory } from 'history';
import { reducer as formReducer } from 'redux-form';
import configureStore from './CreateStore';
import rootSaga from '../sagas/';

// History
const history = createBrowserHistory();

/* ------------- Assemble The Reducers ------------- */
// $FlowFixMe
export const reducers = combineReducers({
  form: formReducer,
  loading: require('./LoadingRedux').reducer,
  error: require('./ErrorRedux').reducer,
  contact: require('../pages/contact/redux/ContactRedux').reducer
});

export default () => {
  let finalReducers = reducers;
  // If rehydration is on use persistReducer otherwise default combineReducers
  //   if (ReduxPersist.active) {
  //     const persistConfig = ReduxPersist.storeConfig;
  //     finalReducers = persistReducer(persistConfig, reducers);
  //   }

  let { store, sagasManager, sagaMiddleware } = configureStore(
    finalReducers,
    rootSaga,
    history
  );

  // $FlowFixMe
  if (module.hot) {
    // $FlowFixMe
    module.hot.accept(() => {
      const nextRootReducer = require('./').reducers;
      store.replaceReducer(nextRootReducer);

      const newYieldedSagas = require('../sagas').default;
      sagasManager.cancel();
      sagasManager.done.then(() => {
        sagasManager = sagaMiddleware.run(newYieldedSagas);
      });
    });
  }

  return {
    store,
    history
  };
};
