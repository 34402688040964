import React from 'react';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { footerView } from './Styles.module.scss';
// Header Comp
const Footer = props => (
  <div
    className={`footer ${footerView} d-flex flex-column justify-content-center align-items-center mt-5`}
  >
    <div className="container pt-3">
      <div className="d-flex flex-row  justify-content-center align-items-center ">
        { <a
          className={`d-flex justify-content-center mt-2 mr-2`}
          href="https://www.facebook.com/weopttechnologies/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <FontAwesomeIcon icon={faFacebook} size="2x" />
        </a> }
        <a
          className={`d-flex justify-content-center mt-2 ml-2`}
          href="https://www.linkedin.com/company/weopt/about/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <FontAwesomeIcon icon={faLinkedin} size="2x" />
        </a>
      </div>
      <div
        id="copyrights"
        className="d-flex flex-column align-items-center justify-content-center mt-2"
      >
        <p>
          &copy; Copyrights <strong>WeOpt</strong>. All Rights Reserved
        </p>
        <div className="credits">Created by WeOpt</div>
      </div>
    </div>
  </div>
);

export default withRouter(Footer);
