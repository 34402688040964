import React from 'react';
import AOS from 'aos';
import { Button } from 'reactstrap';
import homeIllustration from '../../assets/img/CPU_render_NoWorld_Edited.png';
// import { title, subTitle } from './Styles.module.scss';

//* ******************** */
// COMPONENTS PROPS */
//* ******************** */

//* ******************** */
// COMPONENTS STATE */
//* ******************** */

class Home extends React.Component {
  constructor(props) {
    super(props);
    AOS.init();
  }

  // Component Will Receive Props
  componentWillReceiveProps() {
    AOS.refresh();
  }

  // Component Did Mount
  componentDidMount() {
    document.title = 'Home';
  }

  //* ******************** */
  // USER ACTIONS */
  //* ******************** */

  //* ******************** */
  // RENDER */
  //* ******************** */

  render() {
    return (
      <div className="d-flex flex-fill flex-column-reverse flex-xl-row justify-content-around  align-items-center w-100 p-0 p-xl-5 pt-5">
        <div
          className="d-flex flex-fill medium-width flex-column justify-content-center align-items-center  align-items-xl-start p-5"
          data-aos="fade-up"
          data-aos-offset="50"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <h1 className="titleApp mb-2">The Future of Poker Is Here</h1>
          <p className="mb-5">
            We reinvent how poker players <b className="text-primary">share</b>{' '}
            knowledge, <b className="text-primary">tell</b> stories and{' '}
            <b className="text-primary">connect</b>.{' '}
          </p>
          <Button
            className="mt-5"
            color="primary"
            onClick={() => this.props.history.push('/contact')}
          >
            GET IN TOUCH
          </Button>
        </div>
        <div className="d-flex flex-fill medium-width flex-column justify-content-center align-items-end">
          <img
            data-aos="fade-left"
            data-aos-offset="50"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            src={homeIllustration}
            width="100%"
            alt="Home-Illustration"
          />
        </div>
      </div>
    );
  }
}

export default Home;
