import React from 'react';
import AOS from 'aos';
import AgreementConstants from './AgreementConstants';
//* ******************** */
// COMPONENTS STATE */
//* ******************** */

// * Display the term of use of Flop Application
class TermOfUse extends React.Component {
  constructor(props) {
    super(props);
    AOS.init();
  }

  // Component Will Receive Props
  componentWillReceiveProps() {
    AOS.refresh();
  }

  //* ******************** */
  // RENDER */
  //* ******************** */

  render() {
    return (
      <div className="d-flex flex-fill flex-column mt-5 w-75 m-auto">
        <h2 className="mt-5">{AgreementConstants.AGREEMENT_TITLE}</h2>
        <hr className="d-none d-md-flex weopt mb-3" />
        <div>
          <div>
            <p className="text-dark ml-4">
              {AgreementConstants.AGREEMENT_PARAG_1}
            </p>
          </div>

          <div>
            <h3 className="mt-3 pb-1">{AgreementConstants.ABOUT_TITLE}</h3>
            <p className="text-dark ml-4">{AgreementConstants.ABOUT_TEXT}</p>
          </div>

          <div>
            <h3 className="mt-3 pb-1">{AgreementConstants.DEFINITION_TITLE}</h3>
            <p className="text-dark ml-4">
              {AgreementConstants.DEFINITION_PARAG_TEXT}
            </p>
            <p className="text-dark ml-4">
              {AgreementConstants.DEFINITION_1_TEXT}
            </p>
            <p className="text-dark ml-4">
              {AgreementConstants.DEFINITION_2_TEXT}
            </p>
            <p className="text-dark ml-4">
              {AgreementConstants.DEFINITION_3_TEXT}
            </p>
            <p className="text-dark ml-4">
              {AgreementConstants.DEFINITION_4_TEXT}
            </p>
            <p className="text-dark ml-4">
              {AgreementConstants.DEFINITION_5_TEXT}
            </p>
            <p className="text-dark ml-4">
              {AgreementConstants.DEFINITION_6_TEXT}
            </p>
            <p className="text-dark ml-4">
              {AgreementConstants.DEFINITION_7_TEXT}
            </p>
            <p className="text-dark ml-4">
              {AgreementConstants.DEFINITION_8_TEXT}
            </p>
          </div>

          <div>
            <h3 className="mt-3 pb-1">{AgreementConstants.ACCEPT_TITLE}</h3>
            <p className="text-dark ml-4">{AgreementConstants.ACCEPT_1_TEXT}</p>
            <p className="text-dark ml-4">{AgreementConstants.ACCEPT_2_TEXT}</p>
            <p className="text-dark ml-4">{AgreementConstants.ACCEPT_3_TEXT}</p>
          </div>

          <div>
            <h3 className="mt-3 pb-1">{AgreementConstants.LICENCE_TITLE}</h3>
            <p className="text-dark ml-4">
              {AgreementConstants.LICENCE_1_PARAG}
            </p>
            <p className="text-dark ml-4">
              {AgreementConstants.LICENCE_2_PARAG}
            </p>
            <p className="text-dark ml-5">
              {AgreementConstants.LICENCE_1_TEXT}
            </p>
            <p className="text-dark ml-5">
              {AgreementConstants.LICENCE_2_TEXT}
            </p>
            <p className="text-dark ml-5">
              {AgreementConstants.LICENCE_3_TEXT}
            </p>
            <p className="text-dark ml-5">
              {AgreementConstants.LICENCE_4_TEXT}
            </p>
            <p className="text-dark ml-5">
              {AgreementConstants.LICENCE_5_TEXT}
            </p>
          </div>

          <div>
            <h3 className="mt-3 pb-1">{AgreementConstants.LICENCEPRO_TITLE}</h3>
            <p className="text-dark ml-4">
              {AgreementConstants.LICENCEPRO_1_PARAG}
            </p>
            <p className="text-dark ml-4">
              {AgreementConstants.LICENCEPRO_2_PARAG}
            </p>
            <p className="text-dark ml-4">
              {AgreementConstants.LICENCEPRO_3_PARAG}
            </p>
            <p className="text-dark ml-4">
              {AgreementConstants.LICENCEPRO_1_TEXT}
            </p>
            <p className="text-dark ml-4">
              {AgreementConstants.LICENCEPRO_2_TEXT}
            </p>
            <p className="text-dark ml-4">
              {AgreementConstants.LICENCEPRO_3_TEXT}
            </p>
            <p className="text-dark ml-4">
              {AgreementConstants.LICENCEPRO_4_TEXT}
            </p>
            <p className="text-dark ml-4">
              {AgreementConstants.LICENCEPRO_5_TEXT}
            </p>
          </div>

          <div>
            <h3 className="mt-3 pb-1">{AgreementConstants.PROHIB_TITLE}</h3>
            <p className="text-dark ml-4">
              {AgreementConstants.PROHIB_1_PARAG}
            </p>
            <p className="text-dark ml-4">
              {AgreementConstants.PROHIB_2_PARAG}
            </p>
            <p className="text-dark ml-4">
              {AgreementConstants.PROHIB_3_PARAG}
            </p>
            <p className="text-dark ml-5">{AgreementConstants.PROHIB_1_TEXT}</p>
            <p className="text-dark ml-5">{AgreementConstants.PROHIB_2_TEXT}</p>
            <p className="text-dark ml-5">{AgreementConstants.PROHIB_3_TEXT}</p>
            <p className="text-dark ml-5">{AgreementConstants.PROHIB_4_TEXT}</p>
            <p className="text-dark ml-5">{AgreementConstants.PROHIB_5_TEXT}</p>
            <p className="text-dark ml-5">{AgreementConstants.PROHIB_6_TEXT}</p>
            <p className="text-dark ml-5">{AgreementConstants.PROHIB_7_TEXT}</p>
            <p className="text-dark ml-5">{AgreementConstants.PROHIB_8_TEXT}</p>
          </div>

          <div>
            <h3 className="mt-3 pb-1">{AgreementConstants.PLAYERS_TITLE}</h3>
            <p className="text-dark ml-4">
              {AgreementConstants.PLAYERS_1_PARAG}
            </p>
            <p className="text-dark ml-4">
              {AgreementConstants.PLAYERS_2_PARAG}
            </p>
            <p className="text-dark ml-4">
              {AgreementConstants.PLAYERS_3_PARAG}
            </p>
            <p className="text-dark ml-4">
              {AgreementConstants.PLAYERS_4_PARAG}
            </p>
          </div>

          <div>
            <h3 className="mt-3 pb-1">{AgreementConstants.PROPERTY_TITLE}</h3>
            <p className="text-dark ml-4">
              {AgreementConstants.PROPERTY_1_PARAG}
            </p>
            <p className="text-dark ml-4">
              {AgreementConstants.PROPERTY_2_PARAG}
            </p>
            <p className="text-dark ml-4">
              {AgreementConstants.PROPERTY_3_PARAG}
            </p>
          </div>

          <div>
            <h3 className="mt-3 pb-1">{AgreementConstants.PRIVACY_TITLE}</h3>
            <p className="text-dark ml-4">
              {AgreementConstants.PRIVACY_1_PARAG}
            </p>
            <p className="text-dark ml-4">
              {AgreementConstants.PRIVACY_2_PARAG}
            </p>
            <p className="text-dark ml-4">
              {AgreementConstants.PRIVACY_3_PARAG}
            </p>
          </div>

          <div>
            <h3 className="mt-3 pb-1">{AgreementConstants.DISCLAIMER_TITLE}</h3>
            <p className="text-dark ml-4">
              {AgreementConstants.DISCLAIMER_1_PARAG}
            </p>
            <p className="text-dark ml-5">
              {AgreementConstants.DISCLAIMER_1_TEXT}
            </p>
            <p className="text-dark ml-5">
              {AgreementConstants.DISCLAIMER_2_TEXT}
            </p>
            <p className="text-dark ml-5">
              {AgreementConstants.DISCLAIMER_3_TEXT}
            </p>
          </div>

          <div>
            <h3 className="mt-3 pb-1">{AgreementConstants.DISCLAIMER_TITLE}</h3>
            <p className="text-dark ml-4">
              {AgreementConstants.DISCLAIMER_1_PARAG}
            </p>
            <p className="text-dark ml-5">
              {AgreementConstants.DISCLAIMER_1_TEXT}
            </p>
            <p className="text-dark ml-5">
              {AgreementConstants.DISCLAIMER_2_TEXT}
            </p>
            <p className="text-dark ml-5">
              {AgreementConstants.DISCLAIMER_3_TEXT}
            </p>
          </div>

          <div>
            <h3 className="mt-3 pb-1">
              {AgreementConstants.DISCLAIMER_WARRANTY_TITLE}
            </h3>
            <p className="text-dark ml-4">
              {AgreementConstants.DISCLAIMER_WARRANTY_1_PARAG}
            </p>
            <p className="text-dark ml-4">
              {AgreementConstants.DISCLAIMER_WARRANTY_2_PARAG}
            </p>
            <p className="text-dark ml-4">
              {AgreementConstants.DISCLAIMER_WARRANTY_3_PARAG}
            </p>
            <p className="text-dark ml-4">
              {AgreementConstants.DISCLAIMER_WARRANTY_4_PARAG}
            </p>
          </div>

          <div>
            <h3 className="mt-3 pb-1">
              {AgreementConstants.JURISDICTION_TITLE}
            </h3>
            <p className="text-dark ml-4">
              {AgreementConstants.JURISDICTION_1_PARAG}
            </p>
            <p className="text-dark ml-4">
              {AgreementConstants.JURISDICTION_1_PARAG}
            </p>
            <p className="text-dark ml-4">
              {AgreementConstants.JURISDICTION_3_PARAG}
            </p>
          </div>

          <div>
            <h3 className="mt-3 pb-1">{AgreementConstants.SUSPENSION_TITLE}</h3>
            <p className="text-dark ml-4">
              {AgreementConstants.SUSPENSION_1_PARAG}
            </p>
            <p className="text-dark ml-5">
              {AgreementConstants.SUSPENSION_1_TEXT}
            </p>
            <p className="text-dark ml-5">
              {AgreementConstants.SUSPENSION_2_TEXT}
            </p>
            <p className="text-dark ml-5">
              {AgreementConstants.SUSPENSION_3_TEXT}
            </p>
            <p className="text-dark ml-4">
              {AgreementConstants.SUSPENSION_2_PARAG}
            </p>
            <p className="text-dark ml-4">
              {AgreementConstants.SUSPENSION_3_PARAG}
            </p>
          </div>

          <div>
            <h3 className="mt-3 pb-1">
              {AgreementConstants.SEVERABILITY_TITLE}
            </h3>
            <p className="text-dark ml-4">
              {AgreementConstants.SEVERABILITY_PARAG}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default TermOfUse;
