import React from 'react';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
// import { button } from './Styles.module.scss';
// import MenuBurger from './MenuBurger';
import weopt from '../assets/img/WeOpt_NoText_white.png';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

// If an item is Active
const isActive = (location, pathname) => {
  if (!location) {
    return false;
  }
  return location.pathname.includes(pathname);
};

// Header Comp

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false
    };
  }

  toggleNavbar = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  render() {
    return (
      <Navbar expand="md" fixed="top" light>
        <NavbarBrand href="/" className="d-flex">
          <div className="d-flex flex-row justify-content-center align-items-center">
            <img src={weopt} className="logo" alt="We-Opt-Logo" />
            <h5 className="d-flex flex-fill m-0 pl-2 text-secondary">WEOPT</h5>
          </div>
        </NavbarBrand>
        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
        <Collapse isOpen={this.state.collapsed} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem
              className={`${
                isActive(this.props.location, '/home') ? 'active' : ''
              }`}
            >
              <NavLink
                // onClick={this.toggleNavbar}
                className="nav-link"
                to="/home"
              >
                Home
              </NavLink>
            </NavItem>
            <UncontrolledDropdown
              nav
              inNavbar
              className={`${
                isActive(this.props.location, '/about') ? 'active' : ''
              }`}
            >
              <DropdownToggle nav caret>
                About us
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem
                  onClick={() => {
                    // this.toggleNavbar();
                    this.props.history.push('/about/company');
                  }}
                >
                  The Company
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    // this.toggleNavbar();
                    this.props.history.push('/about/team');
                  }}
                >
                  The Team
                </DropdownItem>
                {/* <DropdownItem
                  onClick={() => this.props.history.push('/about/joinus')}
                >
                  Join the team
                </DropdownItem> */}
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown
              nav
              inNavbar
              className={`${
                isActive(this.props.location, '/products') ? 'active' : ''
              }`}
            >
              <DropdownToggle nav caret>
                Products
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem
                  onClick={() => {
                    // this.toggleNavbar();
                    this.props.history.push('/products/flop');
                  }}
                >
                  FLOP - First Land of Poker
                </DropdownItem>
                {/* <DropdownItem
                  onClick={() => {
                    // this.toggleNavbar();
                    this.props.history.push('/products/flop-ad');
                  }}
                >
                  FLOP AD
                </DropdownItem> */}
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown
              nav
              inNavbar
              className={`${
                isActive(this.props.location, '/events') ? 'active' : ''
              }`}
            >
              <DropdownToggle nav caret>
                Events
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem
                  onClick={() => {
                    // this.toggleNavbar();
                    this.props.history.push('/events/papc');
                  }}
                >
                  PAPC
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    // this.toggleNavbar();
                    this.props.history.push('/events/cgtour');
                  }}
                >
                  FLOP - Cash Game Tour
                </DropdownItem>
                {/* <DropdownItem
                  onClick={() => this.props.history.push('/about/joinus')}
                >
                  Join the team
                </DropdownItem> */}
              </DropdownMenu>
            </UncontrolledDropdown>
            <NavItem
              className={`${
                isActive(this.props.location, '/contact') ? 'active' : ''
              }`}
            >
              <NavLink
                // onClick={this.toggleNavbar}
                className="nav-link"
                to="/contact"
              >
                Contact Us
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}

export default withRouter(Header);
